import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/body/pages/contact"

const ContactPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/contact/"}
      title="Contact Kuda Customer Service | The Money App for Africans"
      description="We're a bank that doesn't leave our customers hanging. Everything about us is on the app, but if you ever need help (or just want to chat), we’ve got you."
    />
    <Contact />
  </Layout>
)

export default ContactPage
