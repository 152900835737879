import React, { useEffect, Fragment } from "react";
import CTA from "../general/cta";
import { scrollToElement } from "../../../utility/utils";
import ContactFold from "../general/contactFold";

const entryContent = {
    title: "Contact Us",
    subtitle: "We might be a bank in an app but we don't leave our customers hanging. Everything about us is on the app, but if you ever need help (or you just want to chat), we’ve got you.",
    mail: "help@kuda.com"
}

const Contact = () => {

    useEffect(() => {
        scrollToElement();
        window.addEventListener("scroll", function () {
            scrollToElement();
        });
    }, [])

    return (
        <Fragment>
            <ContactFold title={entryContent.title} subtitle={entryContent.subtitle} supportMail={entryContent.mail}
            tel='0700022555832'
            
            
            />
            <CTA />
        </Fragment>
    )
}

export default Contact;
